<template>
  <transition name="el-fade-in">
     <div class="modal-mask" v-show="visible">
       <transition name="modal">
          <div class="modal-wrapper" v-show="visible">
            <div class="modal-wrapper-header">
              <h2>确认订单</h2>
              <i class="close" @click="onClose"></i>
            </div>
            <div class="modal-wrapper-body">
              <img :src="goodsPic" alt="">
              <!-- <img src="http://hq-exercitation.oss-cn-shenzhen.aliyuncs.com/callback/f7C8SaxMtQ.png" alt=""> -->
              <div class="body-title">
                <h2>
                  商品：{{goodsName}}
                </h2>
                <h3>
                  规格：{{specificationName}}
                </h3>
              </div>
            </div>
            <div class="modal-wrapper-footer">
              <div class="total-money">
                订单合计：<span><span style="font-size: 18px">￥</span>{{orderMoney}}</span>
              </div>
              <div class="confirm-btn" @click="onOk">
                立即收款
              </div>
            </div>
         </div>
       </transition>
    </div>
  </transition>

</template>

<script>
export default {
  name: "ConfirmOrderModal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    goodsName: {
      type: String,
      default: ''
    },
    specificationName: {
      type: String,
      default: ''
    },
    goodsPic: {
      type: String,
      default: ''
    },
    orderMoney: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    onClose() {
      this.$emit("on-close")
    },
    onOk() {
      this.$emit("on-ok")
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 100;
  .modal-wrapper {
    width: 611px;
    height: 312px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    &-header {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 26px;
      > h2 {
        margin-top: 36px;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }
      > i {
        background: url('../../../assets/image/close.png') no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 28px;
        top: 28px;
        font-size: 18px;
        color: #9bacbf;
        cursor: pointer;
        width: 36px;
        height: 36px;
      }
    }
    &-body {
      padding: 30px 26px;
      display: flex;
      &>img {
        width: 172px;
        height: 105px;
        border-radius: 2px;
      }
      .body-title {
        margin-left: 12px;
        &>h2 {
          font-size: 15px;
          font-weight: 700;
          color: #3a3c42;
          margin-bottom: 6px;
        }
        &>h3 {
          font-size: 15px;
          font-weight: 400;
          color: #a3abb8;
        }
      }
    }
    &-footer {
      background: #f5f5fa;
      height: 85px;
      bottom: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      border-radius: 0 0 4px 4px;
      padding-right: 28px;
      .total-money {
        font-size: 15px;
        font-weight: 400;
        color: #666666;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &>span {
          font-size: 24px;
          font-weight: 700;
          color: #f6612f;
        }
      }
      .confirm-btn {
        width: 84px;
        height: 36px;
        line-height: 36px;
        background: #fa6c23;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        user-select: none;
        &:hover {
          opacity: 0.85;
        }
      }
    }
  }
}

</style>