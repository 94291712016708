var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "el-fade-in" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible"
          }
        ],
        staticClass: "modal-mask"
      },
      [
        _c("transition", { attrs: { name: "modal" } }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible"
                }
              ],
              staticClass: "modal-wrapper"
            },
            [
              _c("div", { staticClass: "modal-wrapper-header" }, [
                _c("h2", [_vm._v("确认订单")]),
                _c("i", { staticClass: "close", on: { click: _vm.onClose } })
              ]),
              _c("div", { staticClass: "modal-wrapper-body" }, [
                _c("img", { attrs: { src: _vm.goodsPic, alt: "" } }),
                _c("div", { staticClass: "body-title" }, [
                  _c("h2", [_vm._v(" 商品：" + _vm._s(_vm.goodsName) + " ")]),
                  _c("h3", [
                    _vm._v(" 规格：" + _vm._s(_vm.specificationName) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "modal-wrapper-footer" }, [
                _c("div", { staticClass: "total-money" }, [
                  _vm._v(" 订单合计："),
                  _c("span", [
                    _c("span", { staticStyle: { "font-size": "18px" } }, [
                      _vm._v("￥")
                    ]),
                    _vm._v(_vm._s(_vm.orderMoney))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "confirm-btn", on: { click: _vm.onOk } },
                  [_vm._v(" 立即收款 ")]
                )
              ])
            ]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }